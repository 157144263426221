<template>
    <div class="cd-body">
        <list :menu="menu" :total="apptDataTotal" :isLoading="isLoading">
            <template v-slot:header-left>
                <div>
                    <!-- 合作夥伴 -->
                    <div class="flex text-sm text-dark mb-1" v-if="partnerlist.length > 1">
                        <div class="flex items-center">
                            <feather-icon class="mr-1" icon="MapPinIcon" svgClasses="w-4 h-4"></feather-icon>
                            <span class="whitespace-no-wrap">{{ partner_name }}</span>
                        </div>
                        <div class="flex items-end justify-between">
                            <feather-icon class="mr-1" icon="SwitchIcon" svgClasses="w-4 h-4"></feather-icon>
                            <div class="flex items-center">
                                <span class="ml-4 whitespace-no-wrap text-primary cursor-pointer hover:font-semibold" @click="changePartner()">{{ $t('apptMgmt.otherInstitution') }}</span>
                                <span class="cd-badge-text" v-if="(agent == 1 ? apptUndoneTotal : agentApptUndoneTotal) - undoneQuantity > 0">{{ (agent == 1 ? apptUndoneTotal : agentApptUndoneTotal) - undoneQuantity }}</span>
                            </div>
                        </div>
                    </div>
                    <!-- Title -->
                    <div class="flex">
                        <h3 class="mr-6 whitespace-no-wrap">
                            {{ agent == 1 ? $t('apptMgmt.whosAppt', { name: sDocName }) : $t('apptMgmt.myReferralAppt', { name: sDocName }) }}
                        </h3>
                        <div class="mr-6 flex items-center" v-if="agent == 1">
                            <vs-dropdown class="cursor-pointer hover:font-semibold" vs-custom-content>
                                <span class="whitespace-no-wrap text-primary text-sm">{{ $t('apptMgmt.authDoc') }} ({{ authDoctorList.length }})</span>
                                <vs-dropdown-menu>
                                    <ul style="min-width: 7rem">
                                        <li class="flex p-2 cursor-pointer hover:bg-primary hover:text-white" v-for="(item, index) in authDoctorList" :key="index" @click="changeDoctor(item)">
                                            <span>{{ item.label }}</span>
                                        </li>
                                    </ul>
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </div>
                    </div>
                </div>
            </template>
            <template v-slot:header-right>
                <!-- <div class="flex flex-wrap space-x-2">
                    <div>
                        搜尋
                        <vs-input icon-no-border clearable icon="icon-search" icon-pack="feather" class="w-48 rounded" v-model="query" />
                    </div> -->
                <div class="flex">
                    <!-- 代理特約掛號 -->
                    <div class="cd-dropdown-group">
                        <vs-button class="btn cd-button-4w-icon" type="border" @click="showApptPopup(0)">{{ $t('apptMgmt.agent') }}</vs-button>
                        <vs-dropdown class="cursor-pointer" vs-custom-content vs-trigger-click>
                            <vs-button class="drop" type="border" icon-pack="feather" icon="icon-more-vertical"></vs-button>
                            <vs-dropdown-menu>
                                <ul style="min-width: 8rem">
                                    <li class="flex p-2 cursor-pointer hover:bg-primary hover:text-white" @click="showApptPopup(0)">
                                        <span>{{ $t('apptMgmt.agent') }}</span>
                                    </li>
                                    <li class="flex p-2 cursor-pointer hover:bg-primary hover:text-white" @click="showApptPopup(1)">
                                        <span>{{ $t('apptMgmt.special') }}</span>
                                    </li>
                                    <li class="flex p-2 cursor-pointer hover:bg-primary hover:text-white" @click="showApptPopup(2)">
                                        <span>{{ $t('apptMgmt.consultation') }}</span>
                                    </li>
                                </ul>
                            </vs-dropdown-menu>
                        </vs-dropdown>
                    </div>
                    <!-- 篩選 -->
                    <div class="ml-4 flex items-center text-primary cursor-pointer hover:font-semibold whitespace-no-wrap" v-if="windowWidth <= 1024" @click="switchFilter">
                        <feather-icon class="mx-1" icon="FilterIcon" svgClasses="w-5 h-5"></feather-icon>
                        <span>{{ $t('apptMgmt.filter') }}</span>
                    </div>
                </div>
                <!-- </div> -->
            </template>
            <template v-slot:sidebar>
                <!-- 搜尋 -->
                <div class="mb-base">
                    <vs-input icon-no-border clearable icon="icon-search" icon-pack="feather" class="w-full rounded" v-model="query" />
                </div>
                <!-- 掛號類型 -->
                <div class="mb-base">
                    <div class="mb-3 flex justify-between items-center whitespace-no-wrap">
                        <vs-radio v-model="type" vs-name="type1" vs-value="0" @change="filterQueues" v-if="future == false">{{ $t('apptMgmt.todayType') }}</vs-radio>
                        <vs-radio v-model="type" vs-name="type1" vs-value="0" @change="filterQueues" v-else>{{ $t('apptMgmt.futureType') }}</vs-radio>
                        <div class="w-auto flex items-center text-primary cursor-pointer hover:font-semibold" @click="changeDate">
                            <feather-icon class="mr-1" icon="CalendarIcon" svgClasses="w-4 h-4"></feather-icon>
                            <span v-if="future == false">{{ $t('apptMgmt.future') }}</span>
                            <span v-else>{{ $t('apptMgmt.today') }}</span>
                            <span class="cd-badge-text" v-if="future == true && undoneApptTotal > 0">{{ undoneApptTotal }}</span>
                            <span class="ml-1" v-else-if="future == false && undoneQuantityFuture > 0">({{ undoneQuantityFuture }})</span>
                            <span class="cd-badge-text invisible" v-else></span>
                        </div>
                    </div>
                    <div class="my-2 flex justify-between items-center" style="width: 10em">
                        <vs-radio class="ml-6" v-model="type" vs-name="type2" vs-value="1" @change="filterQueues">{{ $t('apptMgmt.am') }}</vs-radio>
                        <span class="cd-badge-text" :class="general_am > 0 ? '' : 'invisible'">{{ general_am }}</span>
                    </div>
                    <div class="my-2 flex justify-between items-center" style="width: 10em">
                        <vs-radio class="ml-6" v-model="type" vs-name="type3" vs-value="2" @change="filterQueues">{{ $t('apptMgmt.pm') }}</vs-radio>
                        <span class="cd-badge-text" :class="general_pm > 0 ? '' : 'invisible'">{{ general_pm }}</span>
                    </div>
                    <div class="my-2 flex justify-between items-center" style="width: 10em">
                        <vs-radio class="ml-6" v-model="type" vs-name="type4" vs-value="3" @change="filterQueues">{{ $t('apptMgmt.night') }}</vs-radio>
                        <span class="cd-badge-text" :class="general_night > 0 ? '' : 'invisible'">{{ general_night }}</span>
                    </div>
                    <div class="my-2 flex justify-between items-center" style="width: 10em">
                        <vs-radio class="ml-6" v-model="type" vs-name="type5" vs-value="4" @change="filterQueues">{{ $t('apptMgmt.spec') }}</vs-radio>
                        <span class="cd-badge-text" :class="spec > 0 ? '' : 'invisible'">{{ spec }}</span>
                    </div>
                    <div class="my-2 flex justify-between items-center" style="width: 10em">
                        <vs-radio class="ml-6" v-model="type" vs-name="type6" vs-value="5" @change="filterQueues">{{ $t('apptMgmt.emergency') }}</vs-radio>
                        <span class="cd-badge-text" :class="emergency > 0 ? '' : 'invisible'">{{ emergency }}</span>
                    </div>
                    <div class="my-2 flex justify-between items-center" style="width: 10em">
                        <vs-radio class="ml-6" v-model="type" vs-name="type7" vs-value="6" @change="filterQueues">{{ $t('apptMgmt.consultation') }}</vs-radio>
                        <span class="cd-badge-text" :class="cs > 0 ? '' : 'invisible'">{{ cs }}</span>
                    </div>
                </div>
                <!-- 掛號狀態 -->
                <div class="mb-base">
                    <div class="mb-3 flex justify-between items-center">
                        <vs-radio v-model="status" vs-name="status1" vs-value="0" @change="filterQueues">{{ $t('apptMgmt.status') }}</vs-radio
                        ><br />
                    </div>
                    <div class="my-2 flex justify-between items-center">
                        <vs-radio class="ml-6" v-model="status" vs-name="status2" vs-value="2" @change="filterQueues">{{ $t('apptMgmt.unfinish') }}</vs-radio
                        ><br />
                        <span class="cd-badge-text invisible">0</span>
                    </div>
                    <div class="my-2 flex justify-between items-center">
                        <vs-radio class="ml-6" v-model="status" vs-name="status3" vs-value="3" @change="filterQueues">{{ $t('apptMgmt.finish') }}</vs-radio
                        ><br />
                        <span class="cd-badge-text invisible">0</span>
                    </div>
                    <div class="my-2 flex justify-between items-center">
                        <vs-radio class="ml-6" v-model="status" vs-name="status4" vs-value="4" @change="filterQueues">{{ $t('apptMgmt.cancel') }}</vs-radio
                        ><br />
                        <span class="cd-badge-text invisible">0</span>
                    </div>
                </div>
            </template>
            <template v-slot:content>
                <div v-for="(date, i) in dateTimeList" :key="i">
                    <!-- date -->
                    <div class="sm:px-4 px-0">
                        <vs-divider v-if="i != 0" />
                        <span class="text-primary">{{ date.substring(0, 10) }} {{ $common.getInterval(date.substring(11, 12)) }}</span>
                    </div>
                    <!-- each data -->
                    <div class="grid grid-cols-2">
                        <ApptItem :class="windowWidth < 768 || (windowWidth < 1024 && menu == true) ? 'col-span-2' : 'col-span-1'" v-for="(item, index) in apptData" :key="index" :data="item" :date="date" v-if="item.date == date.substr(0, 10) && item.interval == date.substr(11, 1)"></ApptItem>
                    </div>
                </div>
            </template>
        </list>
        <!-- 上傳醫療影像視窗 -->
        <upload-popup :uploadPopupActive="uploadPopupActive" @closePopup="closeUploadPopup"></upload-popup>
    </div>
</template>
<script>
import { fetchAuthDoctorList, getQueues, getQueuesUndoneQuantity } from '@/api/user'
import FeatherIcon from '@/components/FeatherIcon.vue'
import list from '@/components/Module/list.vue'
import ApptItem from '@/components/ApptMgmt/ApptItem.vue'
import uploadPopup from '@/components/uploadPopup'
import { mapState, mapGetters } from 'vuex'
import { debounce } from 'lodash'

export default {
    components: {
        uploadPopup,
        FeatherIcon,
        list,
        ApptItem,
    },
    data() {
        return {
            apptData: [],
            apptDataTotal: 0,
            sDocName: null,
            agent: 0,
            interval: null,
            startDate: this.$common.getCurrentDate(),
            endDate: this.$common.getCurrentDate(),
            type: 0,
            cs: 0,
            status: 0,
            limit: 2000,
            page: 1,
            sdid: null,
            tempDoctorList: [],
            uploadPopupActive: false,
            undoneQuantity: 0,
            undoneQuantityFuture: 0,
            cs: 0,
            spec: 0,
            emergency: 0,
            general_am: 0,
            general_pm: 0,
            general_night: 0,
            future: false,
            patient: {
                pid: null,
                name: null,
            },
            menu: false,
            isLoading: false,
            query: '',
        }
    },
    beforeRouteEnter(to, from, next) {
        if (from.fullPath == '/reservation' || from.fullPath == '/apptmgmt/1' || from.fullPath == '/apptmgmt/2' || to.fullPath == '/apptmgmt/2') {
            next()
        } else if (localStorage.getItem('apptVer') && localStorage.getItem('apptVer') != to.fullPath) {
            next({ path: localStorage.getItem('apptVer') })
        } else {
            next()
        }
    },
    created() {
        // 暫時用一個變數去紀錄使用者用的是新版還是舊版
        localStorage.setItem('apptVer', '/apptmgmt/1')

        this.agent = this.$route.params.agent
        this.sdid = this.did
        this.sDocName = this.$t('apptMgmt.my')
        this.fetchAuthDoctorList()
        this.getQueues()
        this.getQueuesUndoneQuantity()
        this.createBus()
    },
    inject: ['reload'],
    watch: {
        '$route.params'(n, o) {
            if (o.agent && n.agent != o.agent) {
                this.reload()
            }
        },
        page() {
            this.getQueues()
        },
        getMessage(v) {
            if (v.entityType == 'orderQueue' || v.entityType == 'closeQueue') this.getQueues()
        },
        query() {
            this.getQueues()
        },
    },
    computed: {
        ...mapGetters('socketModule', ['getMessage']),
        ...mapState([
            'undoneApptAm',
            'undoneApptPm',
            'undoneApptNight',
            'undoneApptEmergency',
            'undoneApptSpec',
            'undoneApptCs',
            'undoneApptTotal',
            'undoneAgentApptAm',
            'undoneAgentApptPm',
            'undoneAgentApptNight',
            'undoneAgentApptEmergency',
            'undoneAgentApptSpec',
            'undoneAgentApptCs',
            'undoneAgentApptTotal',
            'windowWidth',
            'apptUndoneTotal',
            'agentApptUndoneTotal',
        ]),
        partner_id() {
            return this.$store.getters.partner
        },
        partner_name() {
            const selectedPartner = _.find(this.partnerlist, {
                id: this.partner_id.toString(),
            })
            return selectedPartner.company
        },
        partnerlist() {
            return JSON.parse(localStorage.getItem('partnerlist'))
        },
        did() {
            return this.$store.getters.did
        },
        dname() {
            return this.$store.getters.name
        },
        authDoctorList() {
            // 授權醫師名單(護士用)
            let data = [
                {
                    label: '(' + this.$t('apptMgmt.myself') + ') ' + this.dname,
                    value: this.did,
                },
            ]
            if (this.tempDoctorList.length != 0) {
                this.tempDoctorList.forEach(function (v) {
                    data.push({ label: v.full_name, value: v.doctor_did })
                })
            }
            return data
        },
        getQueuesPayload() {
            const payload = {
                partner_id: this.partner_id,
                is_paid: 1,
                withCall: 1,
                withFreeClinic: 1,
                limit: this.limit,
                page: this.page,
            }
            if (this.startDate) payload.start_date = this.startDate
            if (this.endDate) payload.end_date = this.endDate

            if (this.agent == 2) {
                payload.proxy = this.dmid
            } else {
                payload.sdid = this.sdid
            }

            if (this.type == 1) {
                // 上午診
                payload.interval = [1]
                payload.spec_note = 0
            } else if (this.type == 2) {
                // 下午診
                payload.interval = [2]
                payload.spec_note = 0
            } else if (this.type == 3) {
                // 晚診
                payload.interval = [3]
                payload.spec_note = 0
            } else if (this.type == 4) {
                // 特約
                payload.spec_note = 1
            } else if (this.type == 5) {
                // 急診
                payload.interval = 0
            } else if (this.type == 6) {
                // 會診
                payload.is_cs = 1
            }

            if (this.status == 2) {
                payload.finish = 0
                payload.closed = 0
            } else if (this.status == 3) {
                payload.finish = 1
            } else if (this.status == 4) {
                payload.finish = 0
                payload.closed = 1
            }

            if (this.query != '') payload.query = this.query
            return payload
        },
        dateTimeList() {
            if (this.apptData.length == 0) return []
            let rs = this.apptData.map(function (item) {
                return item.date + '-' + item.interval
            })
            rs = rs.filter(function (item, index, array) {
                return array.indexOf(item) === index
            })
            return rs
        },
        device() {
            return this.$store.getters.device
        },
        dmid() {
            return this.$store.state.mid
        },
        doctorType() {
            return this.$store.state.doctortype
        },
        lang() {
            return this.$i18n.locale
        },
        is_p2p() {
            return localStorage.getItem('is_p2p')
        },
    },
    methods: {
        // 取掛號未完診數量
        getQueuesUndoneQuantity() {
            const payload = {
                sdid: this.sdid,
                proxy: this.dmid,
                partner_id: this.partner_id,
                start_date: this.startDate,
                end_date: this.endDate,
            }
            getQueuesUndoneQuantity(payload).then((res) => {
                const data = res.data.data
                if (this.agent == 2) {
                    this.general_am = data.agent.am
                    this.general_pm = data.agent.pm
                    this.general_night = data.agent.night
                    this.emergency = data.agent.emergency
                    this.spec = data.agent.spec
                    this.cs = data.agent.cs
                } else {
                    this.general_am = data.general.am
                    this.general_pm = data.general.pm
                    this.general_night = data.general.night
                    this.emergency = data.general.emergency
                    this.spec = data.general.spec
                    this.cs = data.general.cs
                }
            })
        },
        // 取授權醫師名單
        async fetchAuthDoctorList() {
            await fetchAuthDoctorList().then((res) => {
                if (res.data.status == 'OK') {
                    this.tempDoctorList = res.data.data
                }
            })
        },
        // 變更合作夥伴
        changePartner() {
            this.$bus.$emit('selectPartner')
        },
        // 變更醫師
        changeDoctor(data) {
            this.sdid = data.value
            this.sDocName = this.sdid == this.did ? this.$t('apptMgmt.my') : data.label + (this.lang == 'en' ? "'s" : '')
            this.filterQueues()
        },
        // 選擇日期
        changeDate() {
            this.future = !this.future
            if (this.future == false) {
                this.startDate = this.endDate = this.$common.getCurrentDate()
            } else {
                this.startDate = this.$common.getCurrentDate(1)
                this.endDate = this.$common.getCurrentDate(999999)
            }
            this.filterQueues()
        },
        // 開啟 掛號視窗
        showApptPopup(index, pid = null, pname = null) {
            const data = {
                apptMode: index,
                patient: {
                    pid: pid ? parseInt(pid) : null,
                    name: pname,
                },
            }
            this.$bus.$emit('appt', data)
        },
        // 開關篩選sidebar (手機版)
        switchFilter() {
            this.menu = !this.menu
        },
        // 開啟 醫療影像上傳視窗
        showUploadPopup(data) {
            this.uploadPopupActive = true
        },
        // 關閉 醫療影像上傳視窗
        closeUploadPopup(v) {
            this.uploadPopupActive = false
        },
        // 取掛號
        getQueues: debounce(function () {
            this.isLoading = true
            getQueues(this.getQueuesPayload)
                .then((res) => {
                    this.apptData = res.data.data.data
                    this.apptDataTotal = res.data.data.total
                })
                .finally(() => {
                    this.isLoading = false
                })
        }, 200),
        // 篩選掛號
        filterQueues() {
            this.page = 1
            this.getQueues()
            this.getQueuesUndoneQuantity()
        },
        // 建立 event bus
        createBus() {
            this.$bus.$on('createAppt', () => {
                this.getQueues()
                this.getQueuesUndoneQuantity()
            })
        },
    },
    beforeDestroy() {
        this.$bus.$off('createAppt')
    },
    beforeRouteLeave(to, from, next) {
        this.uploadPopupActive = false
        next()
    },
}
</script>

<template>
    <div :style="data.finish == 0 && data.closed == 0 ? '' : 'color: #666;'" v-if="data">
        <!-- 會診 -->
        <div class="absolute shadow-md cd-appt-csdiv m-3" :style="'width: ' + cardWidth + 'px; height: ' + cardHeight + 'px; ' + (data.finish == 0 && data.closed == 0 ? 'background-color: #E6E6FA; border: #ccc 1px solid;' : '')" v-if="data.cs_id">
            <div class="absolute flex items-center whitespace-no-wrap text-sm" style="transform: translate(15px, -11px); right: 5%" :style="data.closed == 1 || data.finish == 1 ? 'color: #666 !important;' : 'color: #1f1f9d !important;'" v-if="data.cs_id != 0">
                <feather-icon class="mr-1" icon="UsersIcon" svgClasses="w-4 h-4"></feather-icon>
                <span>{{ $t('apptMgmt.csTags', { dname: data.did != did ? data.doctor_info.family_name + data.doctor_info.name : data.proxy_doctor.family_name + data.proxy_doctor.name }) }}</span>
            </div>
        </div>
        <div ref="card" class="cd-apptmgmt-card sm:py-4 py-2 h-auto relative shadow-md" style="z-index: 1" :style="data.finish == 0 && data.closed == 0 ? 'background-color: #fee; border: #eee 1px solid;' : 'background-color: #fff;'">
            <div class="flex justify-between items-start">
                <div class="mr-2 overflow-hidden">
                    <div class="mb-2 cd-card-title flex whitespace-no-wrap cursor-pointer" :class="data.finish == 0 && data.closed == 0 ? 'text-primary' : 'font-normal'" @click.stop="goToMr(data)">
                        <span class="mr-2 text-sm text-danger" v-if="data.freeClinicProgress">義診 #{{ data.freeClinicProgress.eventPatientNum }}</span>
                        <span class="mr-2 text-sm text-danger" v-else-if="data.spec_note == 1">{{ $t('apptMgmt.shortSpec') }}</span>
                        <span class="mr-2 text-sm text-danger" v-else-if="data.interval == 0">{{ $t('apptMgmt.emergency') }}</span>
                        <span class="mr-2 text-sm text-danger" v-else-if="data.cs_id != 0">會診</span>
                        <span class="mr-2 text-sm" v-else>#{{ data.num }}</span>
                        <span class="mr-1">{{ data.patient_info.name }} </span>
                        <span>, {{ $common.getGender(data.patient_info.sex) }} {{ $common.getAge(data.patient_info.birthday) }}</span>
                    </div>
                </div>
                <!-- 掛號狀態 -->
                <div class="flex items-center">
                    <h3 class="cd-danger-dot mr-1" v-if="data.finish == 0 && data.closed == 0">●</h3>
                    <feather-icon class="mr-1" icon="CheckIcon" svgClasses="w-4 h-4" v-else-if="data.finish == 1"></feather-icon>
                    <feather-icon class="mr-1" icon="XIcon" svgClasses="w-4 h-4" v-else></feather-icon>
                    <span v-if="data.closed == 1 && data.system_closed == 0">
                        {{ $t('apptMgmt.cancel') }}
                    </span>
                    <span v-else-if="data.closed == 1 && data.system_closed == 1"> {{ $t('apptMgmt.cancelSystem') }}</span>
                    <span class="text-danger" v-else-if="data.finish == 0">
                        {{ $t('apptMgmt.unfinish') }}
                    </span>
                    <span v-else>
                        {{ $t('apptMgmt.finish') }}
                    </span>
                </div>
            </div>
            <!-- label -->
            <div class="flex flex-wrap items-center" style="height: 3.5em; overflow: hidden">
                <div class="mr-4 flex items-center whitespace-no-wrap" v-if="data.spec_note == 1 && data.reservation_time">
                    <feather-icon class="mr-1" icon="ClockIcon" svgClasses="w-4 h-4"></feather-icon>
                    <span>{{ data.reservation_time.substr(0, 5) }}</span>
                </div>
                <div class="mr-4 flex items-center whitespace-no-wrap" v-if="data.scheme_time">
                    <feather-icon class="mr-1" icon="TagIcon" svgClasses="w-4 h-4"></feather-icon>
                    <span v-if="data.scheme_time / 60 != 0">{{ data.scheme_time / 60 }}{{ $t('reservation.minutesPlan') }}</span>
                </div>
                <div class="mr-4 flex items-center whitespace-no-wrap" v-if="getPmemo(data.p_memo)">
                    <feather-icon class="mr-1" icon="MessageSquareIcon" svgClasses="w-4 h-4"></feather-icon>
                    <span>{{ getPmemo(data.p_memo) }}&nbsp;</span>
                </div>
                <!-- 被掛號醫師 -->
                <div class="mr-4 flex items-center" v-if="data.did != did">
                    <feather-icon class="mr-1" icon="UserIcon" svgClasses="w-4 h-4"></feather-icon>
                    <span>{{ data.doctor_info.family_name + data.doctor_info.name }}</span>
                </div>
                <!-- 手機 -->
                <div class="mr-4 flex items-center" v-if="data.patient_info.member.mobile">
                    <feather-icon class="mr-1" icon="PhoneIcon" svgClasses="w-4 h-4"></feather-icon>
                    <span>{{ data.patient_info.member.mobile }}</span>
                </div>
                <!-- 到院診 -->
                <div class="mr-4 flex items-center" v-if="data.site == 1">
                    <feather-icon class="mr-1" icon="TagIcon" svgClasses="w-4 h-4"></feather-icon>
                    <span>{{ $t('apptMgmt.onsiteVisit') }}</span>
                </div>
                <!-- 代理人 -->
                <div class="mr-4 flex items-center" v-if="data.proxy_doctor">
                    <feather-icon class="mr-1" icon="TagIcon" svgClasses="w-4 h-4"></feather-icon>
                    <span>{{ $t('apptMgmt.tag', { name: data.proxy_doctor.family_name + data.proxy_doctor.name }) }}</span>
                </div>
                <!-- 地區 -->
                <div class="mr-4 flex items-center" v-if="getLocation(data.p_memo)">
                    <feather-icon class="mr-1" icon="MapPinIcon" svgClasses="w-4 h-4"></feather-icon>
                    <span>{{ getLocation(data.p_memo) }}</span>
                </div>
            </div>
            <!-- 義診 -->
            <div class="flex flex-wrap space-x-4 p-2 mt-2 border border-dashed" v-if="data.freeClinicProgress">
                <!-- <div class="flex items-center whitespace-no-wrap"> -->
                <span>{{ data.freeClinicProgress.eventName }}</span>
                <!-- <span>#{{ data.freeClinicProgress.eventPatientNum }}</span> -->
                <!-- </div> -->
                <!-- <div class="mx-4 flex items-center whitespace-no-wrap cursor-pointer"> -->
                <vx-tooltip :text="getFreeClinicUndoneItem(data.freeClinicProgress.undoneItemName)">
                    <span class="text-danger">{{ $t('apptMgmt.freeClinicUntested', { num: data.freeClinicProgress.undone }) }}</span>
                </vx-tooltip>
                <!-- </div> -->
                <!-- <div class="flex items-center whitespace-no-wrap"> -->
                <span>{{ $t('apptMgmt.freeClinicTested', { num: data.freeClinicProgress.done }) }}</span>
                <!-- </div> -->
            </div>
            <vs-divider />
            <!-- CTA -->
            <div>
                <div class="flex justify-between items-center">
                    <div class="flex items-center whitespace-no-wrap w-32" v-if="data.site == 2 && data.closed == 0">
                        <div class="mr-2 flex items-center text-primary cursor-pointer hover:font-semibold" :style="data.closed == 1 || data.finish == 1 ? 'color: #666 !important;' : ''" @click.stop="call()">
                            <feather-icon class="mx-1" icon="VideoIcon" svgClasses="w-5 h-5"></feather-icon>
                            <span>{{ $t('apptMgmt.call') }}</span>
                        </div>
                        <div style="color: #666">
                            <!-- <span class="text-sm" v-if="(windowWidth >= 576 && windowWidth <= 768) || windowWidth >= 1024">{{ $t('apptMgmt.callBack') }}</span> -->
                            <span class="text-sm" v-if="data.cs_id != 0 && data.proxy_call_did != did">{{ data.callback_doctor.family_name + data.callback_doctor.name }}</span>
                            <span class="text-sm" v-else-if="data.cs_id != 0 && data.did != did">{{ data.doctor_info.family_name + data.doctor_info.name }}</span>
                            <span class="text-sm" v-else-if="data.cs_id == 0 && data.proxy_call_did">{{ data.callback_doctor.family_name + data.callback_doctor.name }}</span>
                            <span class="text-sm" v-else>{{ data.patient_info.name }}</span>
                        </div>
                    </div>
                    <div v-else></div>
                    <div class="flex items-center whitespace-no-wrap" v-if="data.closed == 0">
                        <!-- <div class="flex items-center whitespace-no-wrap" v-if="is_p2p == 0">
                            <div
                                class="mr-2 flex items-center text-success cursor-pointer hover:font-semibold"
                                :style="data.closed == 1 || data.finish == 1 ? 'color: #666 !important;' : ''"
                                @click.stop="showInvitePopup(data.queue_id, data.pid, data.patient_info.mid)"
                                v-if="!data.mcu[0] || !data.mcu[0].data || data.mcu[0].data.patientTotal == 0"
                            >
                                <feather-icon class="mx-1" icon="LinkIcon" svgClasses="w-5 h-5"></feather-icon>
                                <span>{{ $t('apptMgmt.invite') }}</span>
                            </div>
                            <div class="blink flex items-center text-danger cursor-pointer hover:font-semibold" @click.stop="enterRoom(data)" v-else-if="data.mcu[0].data.patientTotal > 0">
                                <feather-icon class="mx-1" icon="LogInIcon" svgClasses="w-5 h-5"></feather-icon>
                                <span>{{ $t('apptMgmt.enterRoom') }}</span>
                            </div>
                        </div> -->
                        <!-- 這是p2p的版本 p2p的條件和MCU相衝突所以分成兩個版本 -->
                        <div
                            class="mr-4 flex items-center cursor-pointer hover:font-semibold"
                            :class="checkInQueue.includes(data.queue_id) || data.closed == 1 || data.finish == 1 ? 'text-grey' : 'text-success'"
                            @click.stop="showInvitePopup(data.queue_id, data.pid, data.patient_info.mid)"
                            v-if="data.site == 2 && is_p2p == 1"
                        >
                            <feather-icon class="mx-1" icon="LinkIcon" svgClasses="w-5 h-5"></feather-icon>
                            <span>{{ $t('apptMgmt.invite') }}</span>
                        </div>
                        <div class="mr-4 flex items-center cursor-pointer hover:font-semibold" :class="checkInQueue.includes(data.queue_id) ? 'text-danger ' : 'text-grey'" @click.stop="call(0, 0)" v-if="data.site == 2 && is_p2p == 1">
                            <feather-icon class="mx-1" icon="LogInIcon" svgClasses="w-5 h-5"></feather-icon>
                            <span>{{ $t('apptMgmt.enterRoom') }}</span>
                        </div>
                        <!-- 這是p2p的版本 -->
                        <vs-dropdown class="mx-2 cursor-pointer hover:font-semibold" vs-custom-content vs-trigger-click>
                            <feather-icon icon="MoreVerticalIcon" svgClasses="w-5 h-5"></feather-icon>
                            <vs-dropdown-menu>
                                <ul style="min-width: 9rem">
                                    <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click.stop="goToMr(data)">
                                        <span>{{ $t('apptMgmt.goToMr') }}</span>
                                    </li>
                                    <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click.stop="showChatPopup(data.patient_info.mid, data.patient_info.name)">
                                        <span>{{ $t('apptMgmt.sendMsg') }}</span>
                                    </li>
                                    <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click.stop="showApptPopup(0, data.pid, data.patient_info.name)">
                                        <span>{{ $t('apptMgmt.agent') }}</span>
                                    </li>
                                    <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click.stop="showApptPopup(1, data.pid, data.patient_info.name)">
                                        <span>{{ $t('apptMgmt.special') }}</span>
                                    </li>
                                    <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click.stop="doCopy(data.sms_url)" v-if="data.site == 2 && data.sms_url">
                                        <span>{{ $t('apptMgmt.copyLink') }}</span>
                                    </li>
                                    <!-- <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
                                                                    <span>上傳醫療影像</span>
                                                                </li> -->
                                </ul>
                            </vs-dropdown-menu>
                        </vs-dropdown>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
export default {
    props: {
        data: {
            type: Object,
            default: null,
            require: true,
        },
        date: {
            type: String,
            default: '',
            require: true,
        },
    },
    data() {
        return {
            cardWidth: 0,
            cardHeight: 0,
            setInterval: null,
        }
    },
    mounted() {
        var _self = this
        _self.setInterval = setInterval(function () {
            _self.checkPatientInRoom()
        }, 5000)
    },
    watch: {
        windowWidth: {
            immediate: true,
            handler(v) {
                this.$nextTick(() => {
                    if (this.$refs.card) {
                        const card = this.$refs.card
                        this.cardWidth = card.offsetWidth
                        this.cardHeight = card.offsetHeight
                    }
                })
            },
        },
    },
    computed: {
        ...mapState(['did', 'checkInQueue']),
        is_p2p() {
            return localStorage.getItem('is_p2p')
        },
        windowWidth() {
            return this.$store.state.windowWidth
        },
    },
    methods: {
        // 開啟該病人的病歷
        goToMr(data) {
            let patientJson = {
                name: data.name,
                pid: data.pid,
                account: data.account,
            }
            this.$store.commit('SET_ACTIVE_PATIENT_INFO', patientJson)
            let routerPayload = {
                path: '/emr2/' + data.pid + '/' + data.queue_id,
            }
            if (data.proxy_call_did) {
                routerPayload.query = {
                    proxy_call_did: data.proxy_call_did,
                    proxy_call_name: data.proxy_call_name,
                    interval: data.interval,
                }
            }
            this.$router.push(routerPayload)
        },
        // 取 服務項目 文字
        getPmemo(memo) {
            if (!memo) return this.$t('reservation.unSay')
            if (_.includes(memo, '地區：')) {
                // 替換全形逗號為半形逗號
                memo = memo.replace(/，/g, ',')
                memo = memo.substr(0, memo.indexOf(',地區：'))
            }
            return _.trim(memo, ',')
        },
        // 取 地區 文字
        getLocation(memo) {
            if (!memo) return null
            // 替換全形逗號為半形逗號
            memo = memo.replace(/，/g, ',')
            return _.includes(memo, '地區：') ? memo.substr(memo.indexOf(',地區：') + 4, memo.length) : null
        },
        // 開啟 邀請視窗
        showInvitePopup(qid, pid, pmid) {
            const data = {
                qid: qid,
                pid: pid,
                mid: pmid,
            }
            this.$bus.$emit('invite', data)
            // this.invitePayload = {
            //     qid: qid,
            //     pid: pid,
            //     pmid: pmid,
            // }
            // this.invitePopupActive = true
        },
        // 關閉 邀請視窗
        // closeInvitePopup(v = null) {
        //     this.invitePopupActive = false
        // },
        // 進入視訊診間(mcu)
        // enterRoom(data) {
        //     this.$store.commit('SET_CALLING', true)
        //     this.$store.dispatch('updateDoctorStatus', 2000)
        //     this.$store.dispatch('setRoomId', data.room_id)
        //     let patientJson = {
        //         name: data.name,
        //         pid: data.pid,
        //         account: data.account,
        //     }
        //     this.$store.commit('SET_ACTIVE_PATIENT_INFO', patientJson)

        //     let payload = {
        //         isDev: this.isDev,
        //         device: this.device,
        //         room_id: data.room_id,
        //         qid: data.queue_id,
        //         did: this.did,
        //         dname: this.dname,
        //         doctorType: this.doctorType,
        //         dmid: this.dmid,
        //     }
        //     this.$store.dispatch('enterRoom', payload)
        //     if (this.$route.path != '/emr2/' + data.pid + '/' + data.queue_id) {
        //         this.$router.push('/emr2/' + data.pid + '/' + data.queue_id)
        //     }
        // },
        // 開啟 傳送訊息視窗
        showChatPopup(mid, name) {
            const data = {
                smid: mid,
                sname: name,
            }
            this.$bus.$emit('chat', data)
        },
        // 開啟 掛號視窗
        showApptPopup(index, pid = null, pname = null) {
            const data = {
                apptMode: index,
                patient: {
                    pid: parseInt(pid),
                    name: pname,
                },
            }
            this.$bus.$emit('appt', data)
        },
        // 複製視訊連結短網址至剪貼簿
        doCopy(url) {
            if (!url) this.notify('gray', 'no', '')
            this.$copyText(url).then((res) => {
                this.notify('success', this.$t('reservation.copyLinkNotify'), '')
            })
        },
        // 取義診未完成項目名稱
        getFreeClinicUndoneItem(data) {
            return _.join(data, ',')
        },
        // 通話
        call(ring = 1, direction = 1) {
            const payload = { queue_id: this.data.queue_id, pid: this.data.patient_info.pid, ring: ring, direction: direction }
            if (this.data.cs_id > 0 && this.data.proxy_call_did != this.did) {
                // 會診 打給另一個醫師
                payload.mid = this.data.callback_doctor.mid
                // payload.name = this.data.callback_doctor.family_name + this.data.callback_doctor.name
                payload.is_doctor = 1
            } else if (this.data.cs_id > 0 && this.data.did != this.did) {
                // 會診 打給另一個醫師
                payload.mid = this.data.doctor_info.mid
                // payload.name = this.data.doctor_info.family_name + this.data.doctor_info.name
                payload.is_doctor = 1
            } else if (this.data.cs_id == 0 && this.data.proxy_call_did > 0) {
                // 代理掛號 打給掛號時指定的醫事人員
                payload.mid = this.data.callback_doctor.mid
                // payload.name = this.data.callback_doctor.family_name + this.data.callback_doctor.name
                payload.is_doctor = 1
            } else {
                // 打給民眾
                payload.mid = this.data.patient_info.mid
                // payload.name = this.data.patient_info.name
                payload.is_doctor = 0
            }
            this.$store.dispatch('call_p2p', payload).finally(() => {
                if (this.$router.currentRoute.fullPath != '/emr2/' + this.data.pid + '/' + this.data.queue_id) {
                    this.$router.push('/emr2/' + this.data.pid + '/' + this.data.queue_id)
                }
            })
        },
        // 定期去呼叫房間裡有沒有病人（只監聽有無病人，醫事人員不會回應！！）
        checkPatientInRoom() {
            const room = this.data.room
            const room_id = room.length > 0 ? room[0].room_id : 0
            if (room_id > 0) {
                const payload = {
                    room_id: room_id,
                    partner_id: this.data.partner_id,
                    mid: this.data.patient_info.mid,
                }
                this.$store.dispatch('checkPatientInRoom', payload)
            }
        },
    },
    destroyed() {
        clearInterval(this.setInterval)
    },
}
</script>
